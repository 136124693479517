import PaymentsUi from './PaymentsUi';
import * as toast from "../../Wrapper/toast/toast";
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addItems, clearCart, clearData } from '../../Redux/Action/ProductAction';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessPopUp from '../../Components/_widgets/Modal/SuccessPopUp';
import { useApplyCoupon } from '../../Services/ApiHelper';
import { useGetCoupon } from '../../Services/ApiHelper';
import { usePlaceOrder } from '../../Services/ApiHelper';
import { useGetCartItem } from '../../Services/ApiHelper';
import { useGetCheckOut } from '../../Services/ApiHelper';
import MainLoader from '../../Components/_widgets/Loader/MainLoader';
const Payments = () => {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [allCoupon, setAllCoupon] = useState([])
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false)
  const [isPlaced, setIsPlaced] = useState(false)
  const [appliedCouponData, setAppliedCouponData] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)
  const [fillCoupon, setFillCoupon] = useState({
    label: '',
    value: '',
    discount: ''

  });
  const [selectCoupon, setSelectCoupon] = useState({
    label: '',
    value: '',
    discount: ''

  });
  const [clearCoupon, setClearCoupon] = useState(false)
  const [couponLoading, setCouponLoading] = useState(false)
  const locationData = useLocation();
  const selectAddress = locationData.state;
  const { mutate: postApplyCoupon } = useApplyCoupon();
  const { mutate: postPlaceOrder } = usePlaceOrder()
  const { mutate: cartpostData } = useGetCartItem();
  const { data: couponData, isError, isLoading, refetch } = useGetCoupon()
  const { data: cartdata, refetch: getCartData } = useGetCheckOut()
  useEffect(() => {
    getData()
  }, [])
  const applyCouponSelection = async (coupon) => {
    setCouponLoading(true)

    if (coupon.value) {
      let payload = {
        amount: cartdata?.data?.total
      }
      postApplyCoupon((payload, coupon?.value), {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {

            setCouponLoading(false)
            setAppliedCouponData(data?.data)
            toast.success(data?.message);
          }
          else {
            setAppliedCouponData("")
            setCouponLoading(false)
            toast.warn(data?.message)
          }
        },
        onError: (err) => {
          toast.error(err)
        },
      });
      // var response = await apiHelper.applyCoupon(payload, coupon?.value,)
      // if (response?.isSuccess) {
      //   toast.success(response?.message)
      //   setCouponLoading(false)
      //   setAppliedCouponData(response?.data)
      // }
      // else {
      //   setAppliedCouponData("")
      //   setCouponLoading(false)0000
      //   toast.warn(response?.message)

      // }
    }
    else {
      toast.warn("Please enter coupon code")
    }
  }
  const applyCoupon = async () => {

    setButtonLoading(true)
    let payload = {
      amount: cartdata?.data?.total
    }
    postApplyCoupon((payload, fillCoupon?.value), {
      onSuccess: (data) => {
        if (data?.isSuccess || data?.code === 200) {

          toast.success(data?.message)
          setButtonLoading(false)
          setAppliedCouponData(data?.data)
        }
        else {
          toast.warn(data?.message)
          setAppliedCouponData("")
          setButtonLoading(false)
        }
      },
      onError: (err) => {
        toast.error(err)
      },
    });
    // var response = await apiHelper.applyCoupon(payload, fillCoupon?.value,)
    // if (response?.isSuccess) {
    //   toast.success(response?.message)
    //   setButtonLoading(false)
    //   setAppliedCouponData(response?.data)
    // }
    // else {
    //   toast.warn(response?.message)
    //   setAppliedCouponData("")
    //   setButtonLoading(false)
    // }
  }
  const convertCoupons = (coupons) => {
    return coupons?.map(coupon => {
      // Map to format desired label and value
      return {
        label: `${coupon.code} - ${coupon?.discount}`,  // Assign coupon id to generate the label
        value: coupon.code,
        discount: coupon.discount
        // Use coupon id as value
      };
    });
  }
  const getCoupon = async () => {


    refetch()
    if (couponData?.isSuccess || couponData?.code === 200) {
      let allcoupon = convertCoupons(couponData?.data)
      setAllCoupon(allcoupon)
      setClearCoupon(false)
      toast.success(couponData.success)

    }
    else {
      toast.warn(couponData?.message)

    }
  }
  const placedOrder = async (payload) => {

    setLoading(true)
    let addIds = []

    payload.products.map((i) => {
      addIds.push(i.product_id)
    })
    let data = {
      products: addIds,
      coupon_id: appliedCouponData?.id,
      user_address_id: selectAddress?.id,
      pay_mode: "CASH"

    }
    postPlaceOrder((data), {
      onSuccess: (data) => {
        if (data?.isSuccess || data?.code === 200) {
          setShow(true)
          setIsPlaced(true)
          cartpostData({}, {
            onSuccess: (data) => {
              if (data.isSuccess || data?.code === 200) {
                dispatch(addItems(data?.data))
              }
            },
            onError: (err) => {

              toast.warn(err)

            },
          });
          //  var cartItem = await apiHelper.getCartItem()
          //  if (cartItem?.isSuccess) {
          //    dispatch(addItems(cartItem?.data))
          //  }
          dispatch(clearCart([]))
          setLoading(false)

        }
        else {
          getData()
          setShow(false)
          setLoading(false)
          toast.warn(data?.message)
        }
      },
      onError: (err) => {
        toast.error(err)
      },
    });
    // var response = await apiHelper.placeOrder(data)

    // if (response?.isSuccess) {
    //   setShow(true)
    //   setIsPlaced(true)
    //   var cartItem = await apiHelper.getCartItem()
    //   if (cartItem?.isSuccess) {
    //     dispatch(addItems(cartItem?.data))
    //   }
    //   dispatch(clearCart([]))
    //   setLoading(false)

    // }
    // else {
    //   getData()
    //   setShow(false)
    //   setLoading(false)
    //   toast.warn(response?.message)
    // }
  }

  const getData = async () => {
    getCartData()
    if (cartdata?.isSuccess || cartdata?.code === 200) {
      setData(cartdata?.data)

    }
    else {
      toast.warn(cartdata?.message)
    }

  }
  const handleClose = () => {
    setShow(false)
    window.scrollTo(0, 0)
    navigate("/")

  }
  const myOrder = () => {
    setShow(false);
    navigate("/order")
  }
  if (loading) {
    return <MainLoader />
  }
  return (
    <>
      <SuccessPopUp
        isPlaced={isPlaced}
        loading={loading}
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myOrder={myOrder}
      />
      <PaymentsUi
        applyCoupon={applyCoupon}

        placedOrder={placedOrder}
        data={data}
        selectAddress={selectAddress}
        getCoupon={getCoupon}
        allCoupon={allCoupon}
        appliedCouponData={appliedCouponData}
        couponLoading={couponLoading}
        buttonLoading={buttonLoading}
        applyCouponSelection={applyCouponSelection}
        setFillCoupon={setFillCoupon}
        setSelectCoupon={setSelectCoupon}
        fillCoupon={fillCoupon}
        selectCoupon={selectCoupon}
        clearCoupon={clearCoupon}
        setClearCoupon={setClearCoupon}
        setAppliedCouponData={setAppliedCouponData}
      />



    </>
  )
}

export default Payments