import Info from '../../../Assets/svgs/Info';
import Button from '../Button/Button';
import Text from '../Text/Text';
import BasicModal from './BasicModal';
import { Form } from 'react-bootstrap';

const ModalConfirmation = ({ show, handleClose, heading, buttonLoading, button1Click, button1Text, footer, address, button2Text, selectedAddress, setSelectedAddress, addressLoading, size = "lg" }) => {

    return (
        <>
            <BasicModal
                size={size}
                show={show}
                handleClose={handleClose}
                heading={`${heading}`}
                headinIcon={<Info />}
                loading={buttonLoading}
                footer={footer}
                button1Click={button1Click}

                button1Text={button1Text}
                button2Text={button2Text}
            >
                <div className='mx-3'>
                    <Text style={{ fontSize: "12px" }} text="Dear Member, Please confirm your delivery address below, Prices and quantities of items may vary accordingly." />
                    <div className='my-3'>
                        {
                            address.map((i) => (
                                <>

                                    <div className='d-flex align-items-start w-100 py-3 border-bottom '>
                                        <b style={{ fontSize: '14px' }} className='w-50'>Delivery Address</b>
                                        <div className="brand-list w-50 text-start">
                                            <Form.Check
                                                type="radio"
                                                id={i.id}
                                                label={`${i.address} ${i.state} , ${i.city}, ${i.pincode}`}
                                                value={selectedAddress?.id}
                                                checked={selectedAddress?.id === i.id}
                                                onChange={(e) => setSelectedAddress({
                                                    ...i, is_default: e.target.checked
                                                })}
                                            />
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    <div className='w-100 d-flex align-items-center mt-5 gap-2'>
                        <Button style={{ width: "100%" }} onClick={() => handleClose()} buttonType="outline" text={button2Text} />
                        <Button style={{ width: "100%" }} isLoading={addressLoading} onClick={() => button1Click()} buttonType="primary" text="Confirm" />
                    </div>
                </div>
            </BasicModal>
        </>
    )
}

export default ModalConfirmation;
