import { USER_PROFILE, USERLOGOUT } from "../../Constants/actionTypeConst";

const initialState = {
    user: null, // Or some other initial value
    loading: false,
    error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_PROFILE:
            // Handle the USER_PROFILE action
            return {
                ...state,
                user: action.payload, // Assuming action.payload contains user data
            };
        case USERLOGOUT:
            return {
                ...state,
                user: null,
            };
        default:
            // Always return the previous state if no relevant action is found
            return state;
    }
};

export default userReducer;
