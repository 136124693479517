
// import React from 'react';
// import StepProgressBar from 'react-step-progress';
// import 'react-step-progress/dist/index.css';
// import './steps.scss';
// import { CANCELLED, CONFIRMED, DELIVERED, INPROGRESS, INVOICE, ORDER_CREATED, OUT_FOR_DELIVERY } from '../../Constants/stringConstants';

// const Created = <span>{ORDER_CREATED} </span>;
// const InProgress = <span>{INPROGRESS} </span>;
// const Invoiced = <span> {INVOICE}</span>;
// const Out_for_delivery = <span> {OUT_FOR_DELIVERY} </span>;
// const Delivered = <span> {DELIVERED} </span>;
// const Confirmed = <span> {CONFIRMED} </span>;
// const Cancelled = <span> {CANCELLED} </span>;

// const CustomStepProgress = ({ step }) => {
//     const getStep = (step) => {
//         let steps = [
//             {
//                 name: 1,
//                 label: ORDER_CREATED,
//                 validator: () => step >= 1,
//             },
//             {
//                 name: 2,
//                 label: INPROGRESS,
//                 validator: () => step >= 2,
//             },
//             {
//                 name: 3,
//                 label: INVOICE,
//                 validator: () => step >= 3,
//             },
//             {
//                 name: 4,
//                 label: OUT_FOR_DELIVERY,
//                 validator: () => step >= 4,
//             },
//             {
//                 name: 5,
//                 label: DELIVERED,
//                 validator: () => step >= 5,
//             },
//         ];

//         if (step === 6) {
//             steps.push({
//                 name: 6,
//                 label: CONFIRMED,
//                 validator: () => step >= 6,
//             });
//         } else if (step === 7) {
//             steps.push({
//                 name: 6,
//                 label: CANCELLED,
//                 validator: () => step >= 7,
//             });
//         }
//         return steps;
//     };
//     console.log("getSteps", getStep(step))
//     return (
//         <>
//             {getStep(step) && (
//                 <div className="custom-step-progress">
//                     <StepProgressBar
//                         startingStep={Math.min(step, 5) - 1} // Start from the last completed step
//                         buttonWrapperClass="step-buttons"
//                         labelClass="step-label"
//                         stepClass="step"
//                         progressClass="progress-line"
//                         steps={getStep(step)}
//                     />
//                 </div>
//             )}
//         </>
//     );
// };

// export default CustomStepProgress;
import React from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import './steps.scss';
import { CANCELLED, CONFIRMED, DELIVERED, INPROGRESS, INVOICE, ORDER_CREATED, OUT_FOR_DELIVERY, PAYMENT_DONE, REFUND, SHIPPED } from '../../Constants/stringConstants';

const CustomStepProgress = ({ step }) => {
    const getStep = (step) => {
        let steps = [
            {
                name: 1,
                label: CONFIRMED,
                // content: <div>Order Created Content</div>,
                validator: () => step >= 1,
            },
            {
                name: 2,
                label: PAYMENT_DONE,
                //content: <div>Inprogress Content</div>,
                validator: () => step >= 2,
            },
            {
                name: 3,
                label: SHIPPED,
                //content: <div>Invoiced Content</div>,
                validator: () => step >= 3,
            },
            {
                name: 4,
                label: DELIVERED,
                //content: <div>Out For Delivery Content</div>,
                validator: () => step >= 4,
            },
            {
                name: 5,
                label: CANCELLED,
                //content: <div>Delivered Content</div>,
                validator: () => step >= 5,
            },
            {
                name: 5,
                label: REFUND,
                //content: <div>Delivered Content</div>,
                validator: () => step >= 5,
            },
        ];



        return steps;
    };

    return (
        <>
            {getStep(step) && (
                <div className="custom-step-progress">
                    <StepProgressBar
                        startingStep={step > 6 ? step - 2 : step - 1} // Start from the current step
                        buttonWrapperClass="step-buttons"
                        labelClass="step-label"
                        stepClass="step"
                        progressClass="progress-line"
                        steps={getStep(step)} // Pass the steps dynamically
                    />
                </div>
            )}
        </>
    );
};

export default CustomStepProgress;
