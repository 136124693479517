import { RUPEE } from "../Constants/storageConstant";

export const maskText = (text = '', length) => { // length = length to exclude the text from last, ex: ******1234

    if (text && length) {

        const maskedPart = 'X'.repeat(length);  // Create the masked part
        const visiblePart = text.slice(length);

        if (length === text?.length) {
            const firstPart = maskedPart.slice(0, maskedPart.length - 2); // First three characters
            const secondPart = maskedPart.slice(maskText.length - 2);  // Last two characters

            return `${RUPEE}${firstPart}.${secondPart}`;

        }
        else {
            return RUPEE + maskedPart + visiblePart;
        }
    }
    return RUPEE + text;  // If text is empty or length is not valid, return the original text
};
