import { useState, useEffect } from "react";
import * as urlconstant from "../Constants/urlConstants";
import * as apiProvider from "./../Services/provider";
import { useQueryClient } from "@tanstack/react-query";
import { useFetch } from "../Services/ReactQueryProvider"; // Import useFetch

const useGetById = (url, isQueryEnabled = urlconstant.IS_QUERY_ENABLE) => {
    const queryClient = useQueryClient();

    // ✅ Dynamically initialize `useFetch`
    const { query, mutate } = useFetch(url, { enabled: isQueryEnabled });

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (payload, callbacks = {}) => {
        if (!isQueryEnabled) {
            setIsLoading(true);
            try {
                const response = await apiProvider.getAll(url);
                setData(response);
                if (callbacks.onSuccess) callbacks.onSuccess(response);
            } catch (err) {
                setError(err);
                if (callbacks.onError) callbacks.onError(err);
            } finally {
                setIsLoading(false);
            }
        } else {
            // ✅ Call `mutate()` to fetch data dynamically when needed
            mutate(
                url, // Pass dynamic endpoint if needed
                {
                    onSuccess: (data) => {
                        queryClient.invalidateQueries([url]); // ✅ Invalidate cache
                        if (callbacks.onSuccess) callbacks.onSuccess(data);
                    },
                    onError: (err) => {
                        if (callbacks.onError) callbacks.onError(err);
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (isQueryEnabled) {
            fetchData();
        }
    }, [url, isQueryEnabled]);

    return {
        refetch: fetchData,
        ...query,
        isLoading: isQueryEnabled ? query.isLoading : isLoading,
        error: isQueryEnabled ? query.error : error,
        data: isQueryEnabled ? query.data?.data : data?.data,
        isError: isQueryEnabled ? query.isError : !!error,
    };
};

export default useGetById;
