import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,

  Breadcrumb,

} from "react-bootstrap";
import "./order.scss";
import { useLocation } from "react-router-dom";
import * as toast from "../../Wrapper/toast/toast";
import * as apiHelper from "../../Services/ApiHelper";
import { formattedValue } from "../../Services/common";
import MainLoader from "../_widgets/Loader/MainLoader";
import IconResource from "../../Wrapper/IconResource/IconResource";
import CustomStepProgress from "../Steps/Steps";
import ProductCard from "../../Pages/Products/ProductCard";
import { CANCELLED, ORDER_DETAIL } from "../../Constants/stringConstants";
import Button from "../_widgets/Button/Button";
import { useOrderCancel } from "../../Services/ApiHelper";
import { useGetOrderById } from "../../Services/ApiHelper";

const OrderDetailUi = () => {
  let location = useLocation();
  let id = location.state;
  const pathnames = location.pathname.split('/').filter(Boolean);
  const [userData, setUserData] = useState({ token: "sample-token" }); // Example userData
  const [buttonLoading, setButtonLoading] = useState(false);

  const { data: orderData, isLoading, isError, refetch } = useGetOrderById(id)
  const { mutate: postData } = useOrderCancel();
  const cancelOrder = async (id) => {
    setButtonLoading(true)
    try {
      postData(id, {
        onSuccess: (data) => {

          if (data?.isSuccess || data?.code === 200) {
            refetch(id);
            setButtonLoading(false)
            toast.success(data.message)

          }
          else if (data?.code === 400) {
            setButtonLoading(false)
            toast.warn(data.message)
          }
        },
        onError: (err) => {
          toast.error(err)
        },
      });
      // const resp = await apiHelper.orderCancel(id, {})
      // if (resp?.isSuccess) {
      //   getProductDetails(id);
      //   setButtonLoading(false)
      //   toast.success(resp.message)

      // }
      // else if (resp?.code === 400) {
      //   setButtonLoading(false)
      //   toast.warn(resp.message)
      // }
      // else {
      //   setButtonLoading(false)
      //   toast.warn(resp?.isSuccess)
      // }

    }
    catch (err) {
      toast.warn(err)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    refetch(id)
  }, [])

  return (
    <>
      {
        isLoading ?
          <MainLoader /> :
          <Container className="cart">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return (
                  <Breadcrumb.Item key={to} href={to}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
            <Row>
              <Col lg={8} md={6}>
                <Row>
                  <Col lg={4}>
                    <h3 className="text-start"> Order Details</h3>
                  </Col>


                  <Col lg={8}>
                    <span className="d-flex w-100 gap-2 justify-content-end">
                      {
                        orderData?.status !== CANCELLED && orderData?.order_status < 5 &&
                        <Button
                          isLoading={buttonLoading}
                          buttonType="primary"
                          onClick={(e) => {
                            cancelOrder(orderData?.id)
                          }}

                          text="Cancel"
                        />}
                      {
                        orderData?.tracking_url &&
                        <Button
                          onClick={(e) => window.open(orderData?.tracking_url, "_blank", "noopener,noreferrer")}
                          buttonType="primary"
                          text="Track Order" />

                      }
                    </span>
                  </Col>




                </Row>
                <Row>
                  <Col>
                    <div className="mt-4 delivery-address border text-start py-3 px-3 bg-white">
                      <h6>
                        Your order cannot be canceled now. Please contact support for
                        further information.{" "}
                      </h6>
                    </div>
                  </Col>
                </Row>
                <rows>
                  <Col className="text-start">
                    <div className="options border py-3 px-3 mt-2 text-start">
                      <h6>
                        OTP <strong className="pl-3">1234</strong>
                      </h6>
                    </div>
                    <small className="bold mt-2">
                      Please share this OTP for delivery conformation/pickup
                    </small>
                  </Col>
                </rows>

                <div className="status my-3">
                  <CustomStepProgress step={orderData?.order_status} />
                </div>

                <div className="d-flex delivery-info border gap-2 bg-white p-3 justify-content-start text-start">
                  <div style={{ width: "30px", height: "30px", display: 'flex', alignItems: "center", justifyContent: "center", background: "var(--primary)", borderRadius: "50%" }}>
                    <IconResource type="home" color="white" />
                  </div>
                  <div>
                    <h6 className="mt-1">
                      <strong>Delivery Address:</strong> {orderData?.user_address?.address}
                    </h6>
                    <h6 className="mt-2">
                      <strong>Billing Address:</strong> {orderData?.user_address?.city}
                    </h6>
                    <h6 className="mt-2">
                      <strong>Mobile:</strong> {orderData?.user_address?.mno}
                    </h6>
                  </div>
                </div>

                <h5 className="text-start my-4">
                  All Items <span>({orderData?.product?.length})</span>
                </h5>
                <Row>
                  {orderData?.order_product?.map((item, e) => (
                    <Col xs={12} sm={12} md={12} lg={8} xl={6}>
                      <ProductCard
                        key={item.id}
                        userData={userData}

                        productId={item.id}
                        summary={ORDER_DETAIL}
                        product={{
                          slug: orderData?.product[e]?.slug,
                          name: orderData?.product[e].name,
                          first_full_url_media: orderData?.product[e]?.first_full_url_media,
                          qty: item?.qty,
                          discounted_price: item?.status === 0 ? item?.discounted_price : null,
                          mrp: item?.mrp,
                          discount_price: item?.status === 0 ? item?.discount_price : null,
                          discount_percentage: item?.status === 0 ? item?.discount_percentage : null,
                          expiry_date: item?.product_id === orderData?.product[e]?.id ? orderData?.product[e]?.expiry_date : null,
                          piece_per_box: item?.product_id === orderData?.product[e]?.id ? orderData?.product[e]?.piece_per_box : null
                        }}

                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4} md={6}>
                <div className="summary-box p-3  bg-white">

                  <div className="d-flex justify-content-between pb-3 align-items-center border-bottom">
                    <h5>Order Summary</h5>

                  </div>
                  <div className="d-flex justify-content-between pb-2 mt-3">
                    <h6 className="small">Order Number</h6>
                    <h6 className="fw-bold small">{orderData?.id}</h6>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Payemnt Mode 1</h6>
                    <h6 className="fw-bold small">{orderData?.pay_mode}</h6>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Order Items</h6>
                    <h6 className="fw-bold small">{orderData?.total_items} Items</h6>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Total MRP</h6>
                    <h6 className="small fw-bold">{formattedValue(orderData?.mrp)}</h6>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Discount Savings</h6>
                    <h6 className="small fw-bold">
                      {formattedValue(orderData?.discount_price)}
                    </h6>
                  </div>
                  {/* <div className="d-flex justify-content-between pb-2">
              <h6 className="small">Promotion Applied</h6>
              <h6 className="small fw-bold"> (-) ₹200.00</h6>
            </div> */}
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Subtotal</h6>
                    <h6 className="small fw-bold"> {formattedValue(orderData?.discounted_price, true)}</h6>
                  </div>
                  {
                    orderData?.coupon_id > 0 ? <div className="d-flex justify-content-between pb-2">
                      <h6 className="small">Coupon Discount</h6>
                      <h6 className="small fw-bold"> {formattedValue(orderData.coupon_discount, true)}</h6>
                    </div> : ""
                  }
                  <div className="d-flex justify-content-between pb-2">
                    <h6 className="small">Delivery Charges</h6>
                    <h6 className="small fw-bold">
                      <span className="text-success">Free</span>
                    </h6>
                  </div>

                  <div className="d-flex justify-content-between pb-2 mt-2">
                    <h5 className="">Total</h5>
                    <div>
                      <h4 className="fw-bold">{formattedValue(orderData?.discounted_price, true)}*</h4>
                    </div>
                  </div>

                  <p className="saving text-success fw-bold py-1 mt-2">
                    You have saved   {orderData.coupon_id > 0 ? formattedValue(parseInt(orderData?.discount_price) + parseInt(orderData.coupon_discount), true) : formattedValue(orderData?.discount_price, true)} on this order!
                  </p>
                  <div className="reorder">
                    {/* <button className="btn btn-theme w-100 text-white mt-3">
                Reorder
              </button> */}
                    <div className="d-flex delivery-info border border-2 bg-white p-3 justify-content-start text-start mt-2">
                      <img src="" />
                      <div>
                        <h6 className="fw-bolder">Guaranteed Returns and Refunds</h6>
                        <h6 className="small text-muted fw-bold lh-2 mt-2">
                          For all return related queries, please visit our store as mentioned below. Kindly bring a copy of your invoice.
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex delivery-info border bg-white p-3 justify-content-start text-start mt-2">
                      <img src="" />
                      <div>
                        <h4 className="fw-bolder">Help and Support</h4>
                        <h6 className="small text-muted fw-bold lh-2 mt-2">
                          Get your questions answered by visiting our FAQ section or call on our helpline <a href="#" className="text-danger ps-2">0000 1111 2222</a>
                        </h6>
                        <button className="btn btn-theme w-100 text-white mt-3">Visit FAQ</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row >
          </Container >
      }
    </>
  );
};

export default OrderDetailUi;
