import { useState, memo, useMemo, useEffect } from "react";
import LoginUi from "./LoginUi";
import * as apiHelper from "../../Services/ApiHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as toast from "../../Wrapper/toast/toast";
import * as stringConstants from "../../Constants/stringConstants";
import * as commonServices from "../../Services/common";
import * as storageConstants from "../../Constants/storageConstant";
import { useDispatch } from "react-redux";
import { addItems } from "../../Redux/Action/ProductAction";
import { addUserProfile } from "../../Redux/Action/UserProfileAction";
import { setUser } from "../../helper/analytics";
import { useLogin } from "../../Services/ApiHelper";
import { useCheckUser } from "../../Services/ApiHelper";
import { useGetProfile } from "../../Services/ApiHelper";
import { useGetCartItem } from "../../Services/ApiHelper";
import { useSendOtp } from "../../Services/ApiHelper";
import { useVerifyOtp } from "../../Services/ApiHelper";
import { useForgotPassword } from "../../Services/ApiHelper";
import { useRegister } from "../../Services/ApiHelper";
import { useUpdatePassword } from "../../Services/ApiHelper";
import { useFetchMasterData } from "../../Services/common";
const Login = memo(({ setShowAuthModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [params] = useSearchParams();
  const { mutate: postData, } = useLogin();
  const { mutate: postresendData, } = useSendOtp();
  const { mutate: cartpostData } = useGetCartItem();
  const { mutate: postDataCheck } = useCheckUser();
  const { mutate: verifyPostData } = useVerifyOtp()
  const { mutate: forgetPostData } = useForgotPassword()
  const { mutate: registerPostData } = useRegister()
  const { mutate: updatePostData } = useUpdatePassword()
  const { data, isError, isLoading, refetch } = useGetProfile()
  const { refetch: fetchMasterData } = useFetchMasterData();
  // const { profileData } = useGetProfile();
  const pageAction = useMemo(() => params.get('action') === 'register' ? 'register' : 'login', [params]);

  const initialLoginData = {
    mno: "",
    name: "",
    email: "",
    password: "",
    s: "",
    otp: "",
    isUserVerified: false,
    isOtpSent: false,
    isOtpResent: false,
    isForget: false,
    pageAction
  }

  const [loginData, setLoginData] = useState(initialLoginData);
  const [loading, setLoading] = useState('');

  const [resendOTPTimer, setResendOTPTimer] = useState(30000);

  const validateUser = async () => {
    if (!loading) {
      if (!loginData.mno) {
        toast.warn(stringConstants.PHONE_REQUIRED);
      } else if (loginData.mno.length !== 10) {
        toast.warn(stringConstants.INCORRECT_PHONE);
      } else {
        setLoading('checkUser');
        postDataCheck({ mno: loginData.mno }, {
          onSuccess: (data) => {
            if (data?.isSuccess || data?.code === 200) {
              setLoginData({ ...loginData, isUserVerified: true });
              setLoading('');
            } else {
              toast.warn(data?.message)
              setLoading('');
            }
          },
          onError: (err) => {
            toast.error(err);
            setLoading('');
          },
        });
        // const resp = await apiHelper.checkUser({ mno: loginData.mno });
        // if (resp?.isSuccess) {
        //   setLoginData({ ...loginData, isUserVerified: true });
        // } else {
        //   toast.error(resp?.message);
        // }

      }
    }
  };

  const handleLogin = async () => {
    if (!loginData.password) {
      toast.warn(stringConstants.PASSWORD_REQUIRED);
    } else if (loginData.password.length < 6) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    } else {
      setLoading('login');
      postData({ mno: loginData.mno, password: loginData.password }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            setUser(data?.data)
            commonServices.storeLocalData(storageConstants.USER_DATA, data?.data);
            // refetch()
            setShowAuthModal(false);
            dispatch(addUserProfile(data?.data))
            commonServices.storeLocalData(storageConstants.PROFILE, data?.data);
            cartpostData({}, {
              onSuccess: (data) => {
                if (data.isSuccess) {
                  dispatch(addItems(data?.data))
                }
              },
              onError: (err) => {

                toast.warn(err)

              },
            });
            fetchMasterData()
            setLoading('');
            setShowAuthModal(false);
            navigate('/');
          } else {
            setLoading('');
            toast.warn(data?.message)
          }
        },
        onError: (err) => {
          setLoading('');
          toast.error(err);
        },
      });
      //   const response = await apiHelper.login({ mno: loginData.mno, password: loginData.password });

      // if (response?.isSuccess) {
      //   // setUser(response?.data)

      //   //commonServices.storeLocalData(storageConstants.USER_DATA, response?.data);

      //   //  const resp = await apiHelper.getProfile();
      //   //dispatch(addUserProfile(resp?.data))
      //   //commonServices.storeLocalData(storageConstants.PROFILE, resp?.data);
      //   var cartItem = await apiHelper.getCartItem();
      //   if (cartItem.isSuccess) {
      //     dispatch(addItems(cartItem?.data))
      //   }
      //   commonServices.fetchMasterData(state => setLoading({ ...loading }));
      //   setLoading('');
      //   setShowAuthModal(false);
      //   navigate('/');
      //   toast.success(response?.message);
      // } else {
      //   setLoading('');
      //   toast.error(response?.message);
      // }
    }
  };

  const handleSendOtp = async () => {
    console.log("clicked here")


    if (!loading) {
      setLoading('sendOtp');
      postresendData({ mno: loginData.mno }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            toast.success(data?.message)
            setResendOTPTimer(30000);
            setLoginData({ ...loginData, otp: "", isOtpSent: true, isOtpResent: !loginData.isOtpResent });
          }
          else {
            toast.error(data?.message)
          }
        },
        onError: (err) => {
          toast.error(data?.message)
        },
      });
      // const resp = await apiHelper.sendOtp({ mno: loginData.mno });
      // if (resp?.isSuccess) {
      //   toast.success(resp?.message)
      //   setResendOTPTimer(30000);
      //   setLoginData({ ...loginData, otp: "", isOtpSent: true, isOtpResent: !loginData.isOtpResent });
      // } else {
      //   toast.error(resp?.message);
      // }
      setLoading('');
    }
  };

  useEffect(() => {
    let timer = null;

    if (loginData.isOtpSent) {
      timer = setInterval(() => {
        setResendOTPTimer(prev => {
          if (prev <= 1000) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [loginData.isOtpResent]);

  const handleVerifyOTP = async () => {

    if (loginData.otp.length !== 4) {
      toast.warn(stringConstants.OTP_REQUIRED)
    } else {
      setLoading('verifyOTP');
      verifyPostData({ mno: loginData.mno, otp: loginData.otp }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            commonServices.storeLocalData(storageConstants.USER_DATA, data?.data);
            commonServices.storeLocalData(storageConstants.PROFILE, data?.data);
            setShowAuthModal(false);
            navigate('/');
            toast.success(data?.message);
          }
          else {
            toast.error(data?.message)
          }
        },
        onError: (err) => {
          toast.error(data?.message)
        },
      });
      // const resp = await apiHelper.verifyOtp({ mno: loginData.mno, otp: loginData.otp });
      // if (resp?.isSuccess) {
      //   commonServices.storeLocalData(storageConstants.USER_DATA, resp?.data);
      //   const response = await apiHelper.getProfile();
      //   commonServices.storeLocalData(storageConstants.PROFILE, response?.data);
      //   setShowAuthModal(false);
      //   navigate('/');
      //   toast.success(resp?.message);
      // } else {
      //   toast.error(resp?.message);
      // }
      setLoading('');
    }
  };

  const handleForgotPassword = async () => {
    if (!loading) {
      setLoading('forgetPassword');
      forgetPostData({ mno: loginData.mno }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            setResendOTPTimer(30000);
            setLoginData({ ...loginData, isOtpSent: true, isForget: true, isOtpResent: !loginData.isOtpResent });
          }
          else {
            toast.error(data?.message)
          }
        },
        onError: (err) => {
          toast.error(data?.message)
        },
      });
      // const resp = await apiHelper.sendOtp({ mno: loginData.mno });
      // if (resp?.isSuccess) {
      //   setResendOTPTimer(30000);
      //   setLoginData({ ...loginData, isOtpSent: true, isForget: true, isOtpResent: !loginData.isOtpResent });
      // } else {
      //   toast.error(resp?.message);
      // }
      setLoading('');
    }
  };

  const validateUserForRegister = async () => {

    if (!loginData.name) {
      toast.warn(stringConstants.NAME_REQUIRED);
    }
    else if (!loginData.mno) {
      toast.warn(stringConstants.PHONE_REQUIRED);
    } else if (loginData.mno.length !== 10) {
      toast.warn(stringConstants.INCORRECT_PHONE);
    } else if (loginData.email && !commonServices.isEmailValid(loginData.email)) {
      toast.warn(stringConstants.INCORRECT_EMAIL);
    } else if (!loginData.password) {
      toast.warn(stringConstants.PASSWORD_REQUIRED);
    } else if (loginData.password.length < 6) {
      toast.warn(stringConstants.INVALID_PASSWORD);
    } else {
      setLoading('checkUser');
      const { name, mno, email, password } = loginData;
      registerPostData({ first_name: name, mno, email, password }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            setLoading('');
            setLoginData(prev => ({ ...prev, isOtpSent: true, isOtpResent: true, isUserVerified: true }));
            toast.success(data?.message);
          }
          else {
            toast.error(data?.message)
          }
        },
        onError: (err) => {
          toast.error(data?.message)
        },
      });
      // const response = await apiHelper.register({ first_name: name, mno, email, password });
      // setLoading('');
      // if (response?.isSuccess) {
      //   setLoginData(prev => ({ ...prev, isOtpSent: true, isOtpResent: true, isUserVerified: true }));
      //   toast.success(response?.message);
      // } else {
      //   toast.error(response?.message);
      // }
    }
  };
  const handleUpdatePassword = async () => {

    if (!loading) {
      setLoading('forgetPassword');
      updatePostData({ mno: loginData.mno, otp: loginData?.otp, new_password: loginData.password, confirm_password: loginData.confirmPassowrd }, {
        onSuccess: (data) => {
          if (data?.isSuccess || data?.code === 200) {
            toast.success(data?.message)
            setShowAuthModal(false)
          }
          else {
            toast.error(data?.message)
          }
        },
        onError: (err) => {
          toast.error(data?.message)
        },
      });
      // const resp = await apiHelper.updatePassword({ mno: loginData.mno, otp: loginData?.otp, new_password: loginData.password, confirm_password: loginData.confirmPassowrd });
      // if (resp?.isSuccess) {
      //   toast.success(resp?.message)
      //   setShowAuthModal(false)
      // } else {
      //   toast.error(resp?.message);
      // }
      setLoading('');
    }
  }
  return (
    <LoginUi
      loading={loading}
      navigate={navigate}
      initialLoginData={initialLoginData}
      loginData={loginData}
      setLoginData={setLoginData}
      handleLogin={handleLogin}
      validateUser={validateUser}
      handleSendOtp={handleSendOtp}
      resendOTPTimer={resendOTPTimer}
      handleVerifyOTP={handleVerifyOTP}
      handleForgotPassword={handleForgotPassword}
      validateUserForRegister={validateUserForRegister}
      handleUpdatePassword={handleUpdatePassword}
    />
  );
});

export default Login;