import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as urlconstant from "../Constants/urlConstants";
import axios from 'axios';
import * as commonServices from './common';
import * as storageConstants from '../Constants/storageConstant';
const isQueryEnabled = urlconstant.IS_QUERY_ENABLE;

const putData = async ({ url, payload }) => {
    const { data } = await axios.put(url, payload);
    return data;
};
const postData = async ({ url, payload }) => {
    const headers = getHeaders();
    if (url && payload) {
        const baseUrl = urlconstant.BASE_URL;
        const fullUrl = baseUrl + url;
        const { data } = await axios.post(fullUrl, payload, { headers: headers });
        return data;
    }
};


export const useQueryPost = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ url, payload }) => postData({ url, payload }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([variables.url]); // Invalidate only the specific query
        },

    });
};

export const getHeaders = () => {
    const auth = commonServices.getLocalData(storageConstants.USER_DATA)?.token;

    return {
        'Accept': 'application/json',
        'Authorization': auth !== null ? `Bearer ${auth}` : null,
    };
};
const fetchData = async (url) => {

    const headers = getHeaders();
    const { data } = await axios.get(url, { headers: headers });
    return data;
};
const deleteData = async (url, id) => {

    if (id) {
        const headers = getHeaders();
        const baseUrl = urlconstant.BASE_URL;
        const fullUrl = baseUrl + url + id;
        const { data } = await axios.delete(fullUrl, { headers: headers });
        return data;
    }

};
export const useFetch = (url, options = {}) => {
    const queryClient = useQueryClient();
    const baseUrl = urlconstant.BASE_URL;
    const fullUrl = `${baseUrl}${url}`;

    const isQueryEnabled = options.enabled ?? urlconstant.IS_QUERY_ENABLE;

    // ✅ `useQuery` for automatic caching
    const queryResult = useQuery({
        queryKey: [url], // Cache results per endpoint
        queryFn: () => fetchData(fullUrl), // Fetch data automatically
        enabled: isQueryEnabled, // Run automatically if enabled
        staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
        cacheTime: 10 * 60 * 1000, // Keep unused data for 10 minutes
        refetchOnWindowFocus: false, // Prevent refetching when switching tabs
        ...options,
    });

    // ✅ `useMutation` for manual fetching (button click, event-driven)
    const mutationResult = useMutation({
        mutationFn: async (customUrl) => {
            const endpoint = customUrl ? `${baseUrl}${customUrl}` : fullUrl;
            return fetchData(endpoint);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([url]); // Invalidate cache after mutation
        },
        ...options,
    });

    return { query: queryResult, mutate: mutationResult.mutate };
};


// export const useQueryPost = (url) => {
//     const queryClient = useQueryClient();
//     return useMutation({
//         mutationFn: ({ url, payload }) => postData({ url, payload }), // Fix argument structure
//         enabled: isQueryEnabled,
//         onSuccess: () => queryClient.invalidateQueries(),
//     });
// };


export const useQueryPut = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: putData,
        onSuccess: () => queryClient.invalidateQueries(),
    });
};

export const useQueryDelete = (url) => {

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => deleteData(url, id),
        enabled: isQueryEnabled,
        onSuccess: () => queryClient.invalidateQueries(),
    });
};
