import React from 'react';
import { Container, Row, Col, Form, Badge, Breadcrumb, Table } from 'react-bootstrap';
import './Cart.scss';
import ModalConfirmation from '../../Components/_widgets/Modal/ModalConfirmation';
import { Link } from 'react-router-dom';
import Button from '../../Components/_widgets/Button/Button';
import IconResource from '../../Wrapper/IconResource/IconResource';
import { formattedValue } from '../../Services/common';
import noimg from '../../Assets/img/noproduct.png'
import { slice } from '../../Utils/stringUtils';
import { PRODUCT_DETAIL } from '../../Constants/urlConstants';
import { changeDateFormatForDisplay, changeDateFormatForServer } from '../../Utils/dateUtils';
import CustomTooltip from '../../Components/_widgets/Tooltip/CustomTooltip';
import Text from '../../Components/_widgets/Text/Text';
import { getPiecePrePrice } from '../../Utils/priceUtils';
import { FREE } from '../../Constants/stringConstants';
import * as toast from "../../Wrapper/toast/toast";
const CartUi = ({ cartItems, showModal, setShowModal, confirmCheckout, setSelectDefaultAddress, getData, setSelectedAddress, selectedAddress, getAddressData, setAddressData, address, selectDefaultAddress, confirmDefaultAddress, handleDelectItem, handleAddItem, getProductQuentity, addProduct, getProductPrice, productQty, setProductQty, coupon, setCoupon, applyCoupon, addToWishList, emptyCart, removeToWishList, getDefaultAddress, buttonLoading, handleClose, addressLoading, handleClosePayment }) => {



    return (
        <Container className="cart">
            <ModalConfirmation
                show={showModal}
                heading={selectDefaultAddress ? `Select Delivery Address` : `Delivery/Pickup Confirmation`}
                footer={false}
                button1Text={selectDefaultAddress ? "Confirm" : "ConfirmToCheckOut"}
                button1Click={selectDefaultAddress ? confirmCheckout : confirmDefaultAddress}
                button2Text={"Cancel"}
                handleClose={() => selectDefaultAddress ? handleClosePayment() : handleClose()}
                address={address}
                size='md'
                setAddressData={setAddressData}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                addressLoading={addressLoading}

            />
            <Breadcrumb style={{ fontSize: "var(--p0)", fontWeight: "600" }}>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Cart</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col xl={8} lg={10} md={12} className="bg-white rounded-2">

                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="text-start">My Cart <span className=" h6"><span className="fw-bold">{cartItems?.items?.length} items</span> in Cart</span></h4>
                        <div className="text-end">
                            <Link to="/cart" className="text-danger fw-bold small" onClick={() => emptyCart()}>
                                Clear cart
                            </Link>
                        </div>
                    </div>
                    <div className="delivery-address border d-flex  my-2 align-items-center">
                        <div className="d-flex gap-1">
                            <span className='home-icon'>  <IconResource color="#ffffff" size={10} type="delivery" /></span>
                            <div className="d-flex w-100 flex-wrap align-items-center gap-1 flex-direction-column">

                                <span>  Delivering to</span>
                                <span className="address-text fw-bold">
                                    {getDefaultAddress(address) || ''}
                                </span>
                            </div>

                        </div>
                        {
                            address?.length ?
                                <Link
                                    style={{ color: "var(--primary)" }}
                                    className="ms-2 fw-bold"
                                    onClick={(e) => {
                                        setSelectDefaultAddress(false)
                                        setShowModal(true)
                                    }}
                                    to="/cart"
                                >
                                    Change
                                </Link> :
                                <Link
                                    style={{ color: "var(--primary)" }}
                                    className="ms-2 fw-bold"

                                    to="/my-account"
                                >
                                    Add Address
                                </Link>
                        }

                    </div>

                    {cartItems?.items?.map(item => (
                        <div className="product-item mb-3 p-2" style={{ border: "1px solid var(--border-light)" }} key={item?.products?.id}>
                            <Row>
                                <Col sm={4} md={6} lg={4}>
                                    <div className="product-info d-flex align-items-center gap-1 cart-item">

                                        <Link to={`/${PRODUCT_DETAIL}/${item?.products?.slug}`}>
                                            <div className="product-image-container">
                                                <img
                                                    src={item?.products?.first_full_url_media || noimg}
                                                    alt={item?.products?.name}

                                                />
                                            </div>
                                        </Link>

                                        <div className="product-details">
                                            <Link to={`/${PRODUCT_DETAIL}/${item?.products?.slug}`}>
                                                <h6 className="lh-custom fw-bold mb-2">{slice(item?.products?.name, 20)}</h6>
                                            </Link>
                                            <div className="d-flex align-items-center">
                                                {item?.status === 0 && <>
                                                    <div className='d-flex w-100 justify-content-start gap-1'>
                                                        <small style={{ color: "var(--primary)" }} className="fw-bold">
                                                            {formattedValue(item?.products?.discounted_price, true, true)}
                                                        </small>
                                                        <s style={{ fontSize: "12px", color: "gray", whiteSpace: "nowrap" }}>MRP: {formattedValue(item?.products?.mrp, true, true)}</s>
                                                    </div>
                                                    <IconResource type="help" title="All prices are inclusive of taxes." />
                                                </>}
                                            </div>
                                            {item?.products?.slab && <div className="text-success">Slab prices available</div>}
                                            {item?.status === 0 && <Badge pill bg="light" style={{ fontSize: "10px" }} className="text-success my-2 me-2">
                                                Discount: {formattedValue(item?.products?.discount_price, true, true)} | {item?.products?.discount_percentage}% Off
                                            </Badge>}
                                            {
                                                item?.status === 0 && <div>
                                                    <Link to="/cart" onClick={() => removeToWishList(item.products)}>
                                                        <small style={{ color: "var(--primary)" }} className="fw-bold">
                                                            Remove
                                                        </small>
                                                    </Link>
                                                    <span> | </span>
                                                    <Link to="/cart" onClick={() => addToWishList(item.products)}>
                                                        <small className="fw-bold">
                                                            Move to wishlist
                                                        </small>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <div className="product-extra-info d-flex align-items-center">
                                        {item?.products?.expiry_date &&
                                            <div className="product-expiry d-flex flex-column justify-content-center align-items-center">
                                                <div>
                                                    <CustomTooltip text={"Expiry Date"}>

                                                        <IconResource color="var(--secondary)" size={18} type="calendar" />

                                                    </CustomTooltip>
                                                </div>
                                                <span style={{ fontSize: "10px" }} className="bg-white text-black mt-1">{changeDateFormatForDisplay(item?.products?.expiry_date) || ""}</span>
                                            </div>}
                                        {item?.products?.piece_per_box && item?.status === 0 &&
                                            <div className="product-units d-flex flex-column justify-content-center align-items-center">
                                                <CustomTooltip text="Units per Box">
                                                    <IconResource color="var(--secondary)" size={13} type="box" />
                                                </CustomTooltip>
                                                <span style={{ fontSize: "10px" }} className="bg-white text-black mt-1">{`${item?.products?.piece_per_box} Units/box`}</span>
                                            </div>}
                                        {item?.status === 0 &&
                                            <div className="product-price d-flex flex-column justify-content-center align-items-center">
                                                <CustomTooltip text="Price Per Unit">
                                                    <IconResource color="var(--secondary)" size={15} type="rupee" />
                                                </CustomTooltip>
                                                <span style={{ fontSize: "10px" }} className="bg-white text-black mt-1">{`${formattedValue(getPiecePrePrice(item?.products?.discounted_price), true, true)}/Unit`}</span>
                                            </div>}
                                    </div></Col>
                                <Col sm={6} md={8} lg={2}>
                                    {item?.status === 0 ? (
                                        <div className="product-actions">
                                            <div className="quantity-control purcaase-icons">
                                                <Button
                                                    disabled={item?.products?.quentity === 0}
                                                    onClick={() => handleDelectItem((productQty?.find((i) => i.id === item?.products.id)))}
                                                    icon={<IconResource type="minus" />}
                                                    buttonType="icon"
                                                />
                                                <Form.Control className="text-center form-control" type="text" value={getProductQuentity(item?.products)?.value} readOnly />
                                                <Button
                                                    onClick={() => handleAddItem(productQty?.find((i) => i.id === item?.products.id))}
                                                    icon={<IconResource type="plus" />}
                                                    buttonType="icon"
                                                />
                                            </div>
                                            <Button
                                                isLoading={buttonLoading[item?.products?.id] || false}
                                                className={"btn emr-btn__primary rounded-pill w-100 mt-1"}
                                                onClick={() => addProduct(getProductQuentity(item?.products))}

                                                text="Update" buttonType="primary"
                                            />
                                        </div>
                                    ) : (
                                        <div className="product-actions">
                                            <div className="quantity-control purcaase-icons" style={{ maxWidth: "60px" }}>
                                                <Form.Control className="text-center" type="text" value={item?.qty} readOnly />
                                            </div>
                                        </div>
                                    )}</Col>
                                <Col sm={6} md={4} lg={2}>
                                    {item?.status === 0 ? (
                                        <div className="product-total text-end">
                                            <Text
                                                type='h6'
                                                className="fw-bold"
                                                text={formattedValue(getProductPrice(item), true)} />
                                            {/* <h6 className="fw-bold">{formattedValue(getProductPrice(item), true)}</h6> */}
                                        </div>
                                    ) : (
                                        <div className="product-total text-end">
                                            <Text
                                                type='h6'
                                                className="text-success fw-bold"
                                                text={FREE} />
                                            {/* <Badge pill bg="light" style={{ fontSize: "20px" }}>
                                                {FREE}
                                            </Badge> */}
                                        </div>
                                    )}</Col>
                            </Row>







                        </div>
                    ))}
                </Col>

                {cartItems?.items?.length > 0 && (
                    <Col xl={4} lg={10} md={12}>
                        <div className="summary-box bg-white rounded-2 border">
                            <div className="d-flex justify-content-between pb-2">
                                <h6 className="small">Order Items</h6>
                                <h6 className="fw-bold small">{cartItems?.order_items} Items</h6>
                            </div>
                            <div className="d-flex justify-content-between pb-2">
                                <h6 className="small">Total MRP</h6>
                                <h6 className="small fw-bold">{formattedValue(cartItems?.total_mrp, true)}</h6>
                            </div>
                            <div className="d-flex justify-content-between pb-2">
                                <h6 className="small">Discount Savings</h6>
                                <h6 className="small fw-bold"> - {formattedValue(cartItems?.margin_savings, true)} </h6>
                            </div>
                            <div className="d-flex justify-content-between pb-2">
                                <h6 className="small">Subtotal</h6>
                                <h6 className="small fw-bold">{formattedValue(cartItems?.subtotal, true)}</h6>
                            </div>
                            <div className="d-flex justify-content-between pb-2 mt-2">
                                <h5 className="">Total</h5>
                                <div>
                                    <h4 className="fw-bold">{formattedValue(cartItems?.total, true)}*</h4>
                                    <p>*excl. of delivery fee</p>
                                </div>
                            </div>
                            <p className="saving text-success fw-bold py-1 mt-2">
                                You have saved {formattedValue(cartItems?.margin_savings, true)} on this order!
                            </p>
                            <Button
                                buttonType="fullwidth"
                                text="Proceed to Checkout"
                                onClick={() => {
                                    if (address?.length) {
                                        setShowModal(true)
                                        setSelectDefaultAddress(true)
                                    }
                                    else {
                                        toast.warn("Please add address")
                                    }
                                }}
                            />
                        </div>
                    </Col>
                )}
            </Row>

        </Container>
    );
};

export default CartUi;