import { ADDCART, USERLOGOUT, CLEARCART } from "../../Constants/actionTypeConst"
const cart = [];

const ProductReducer = (state = cart, action) => {

    switch (action.type) {

        case ADDCART:

            return [action.payload]

        case CLEARCART: // Clear the cart when the CLEAR_CART action is dispatched
            return [];
        case USERLOGOUT:
            return []

        default:
            return state;
    }
}
export default ProductReducer;