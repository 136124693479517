export const CONTACT_ADMIN = "Please contact ADMIN";
export const BROWSER_STORAGE_ERROR = "Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.";

export const EMAIL_REQUIRED = "Please enter the email";
export const PASSWORD_REQUIRED = "Please enter the password";
export const EMAIL_PASSWORD_REQUIRED = "Please enter email and password";
export const NAME_REQUIRED = "Please enter full name";
export const PHONE_REQUIRED = "Please enter Phone Number";
export const INCORRECT_PHONE = "Please enter a valid Phone Number";
export const FIRST_NAME_REQUIRED = "Please enter First Name";
export const PHOTO_REQUIRED = "Please upload your photo";
export const FIRST_NAME_LIMIT_ERROR = 'First Name should be less than 30 characters';
export const LAST_NAME_REQUIRED = "Please enter Last Name";
export const LAST_NAME_LIMIT_ERROR = 'Last Name should be less than 30 characters';
export const CONFIRM_PASSWORD_REQUIRED = "Please confirm your password";
export const PASSWORD_CONFIRM_PASSWORD_MATCH = "Password and confirm password do not match";
export const OLD_PASSWORD_REQUIRED = "Please enter Old Password";
export const NEW_PASSWORD_REQUIRED = "Please enter New Password";
export const NEW_CONFIRM_PASSWORD_REQUIRED = "Please confirm your Password";
export const INVALID_PASSWORD = "Please enter password between 6 to 30 characters";
export const OTP_REQUIRED = "Please enter Four Digit OTP to proceed";
export const VALID_OTP = "Please enter 4 Digit OTP to proceed";
export const OTP_SENT_SUCCESS = "Email Validation OTP has been sent successfully";
export const OTP_SENT_FAILURE = "Please retry after 30 seconds.";
export const INCORRECT_EMAIL = "The email must be a valid email address";
export const SOMETHING_WENT_WRONG = "We seem to have hit a snag. Please retry in a while.";
export const TRANSPORTER_NAME_REQUIRED = "Please enter Transporter Name";
export const TRANSPORTER_REMARKS_REQUIRED = "Please enter Transporter Notes/Remarks";
export const SITE_NAME_REQUIRED = "Please select Site Name";
export const ACCOUNTANT_NAME_REQUIRED = "Please enter Accountant Name";
export const SITE_NAME_REQ = "Please add Site Name";
export const SITE_DESC_REQUIRED = "Please add Site Description";
export const START_DATE_REQUIRED = "Please Select Start Date";
export const END_DATE_REQUIRED = "Please Select End Date";

export const ORDER_DATE_REQUIRED = "Please enter the Date";
export const ORDER_CHALLAN_NO_REQUIRED = "Please enter the Challan No";
export const ORDER_MATERIAL_REQUIRED = "Please enter the Material";
export const ORDER_VEHICAL_NO_REQUIRED = "Please enter the Vehical No";
export const ORDER_GROSS_WT_REQUIRED = "Please enter the Gross Weight";
export const ORDER_PERCENTAGE_REQUIRED = "Please enter the Percentage";
export const ORDER_NET_WT_REQUIRED = "Please enter the Net Weight";
export const ORDER_TRANSPORTER_REQUIRED = "Please enter the Transporter";
export const ORDER_SUPPLIER_LOADING_SOURCE_REQUIRED = "Please enter the Supplier/Loading Source";
export const ORDER_ROYALITY_REQUIRED = "Please enter the Royality";
export const ORDER_COMPANY_TICKET_NO_REQUIRED = "Please enter the Company Ticket No";
export const ORDER_ACCOUNTANT_REQUIRED = "Please enter the Accountant";
export const ORDER_REMARKS_REQUIRED = "Please enter Order Notes/Remarks";
export const BRAND_TYPE = "brands";
export const PRICE_TYPE = "price";
export const MARGIN_TYPE = "margin";
export const FRAGRANCE_TYPE = "fragrance";
export const CATAGERY_TYPE = "category";
export const SUB_BRAND_TYPE = "sub_brand";
export const SUB_CATAGERY_TYPE = "sub_category";
export const TYPE = "type";
export const PRODUCT_TYPE = "product"
export const ORDER_DETAIL = "order_detail"
export const MASK = "******"
export const FREE = "FREE";
export const ORDER_CREATED = "Order Created"
export const PAYMENT_DONE = "Payment Done"
export const SHIPPED = "Shipped"
export const CONFIRMED = "Confirmed"
export const REFUND = "Refunded"
export const INPROGRESS = "Inprogress"
export const INVOICE = "Invoiced"
export const OUT_FOR_DELIVERY = "Out For Delivery"
export const DELIVERED = "Delivered"
export const CANCELLED = "Cancelled"









export const MAX_AMT_LIMIT_REACHED = `Max ₹9,99,99.99 Amount is allowed`;
export const MAX_PERCENTAGE_LIMIT_REACHED = `Max 100% is allowed`;
export const MAX_WEIGTH_REACHED = `Max 10,000 KG is allowed`;

export const minimumLengthRequired = (length) => {
    return `Minimum ${length} characters required`
}
export const maximumLengthAllowed = (length) => {
    return `Maximum ${length} characters Allowed`
}
