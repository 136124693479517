
import * as urlConstants from '../Constants/urlConstants';
import useGet from '../CustomHook/useGet';
import usePost from '../CustomHook/usePost';
import useDelete from '../CustomHook/useDelete';
import useGetById from '../CustomHook/useGetById';
export function useHomeData(reqData) {
  return useGet(urlConstants.HOME, reqData)
}
export function useMasterData(query) {
  return useGet(urlConstants.MASTER_DATA + query);
}

export function useSystemData() {
  return useGet(urlConstants.SYSTEM_DATA);
}


export function useLogin(reqData) {
  return usePost(urlConstants.LOGIN, reqData);
}



export function useLogout(reqData) {
  return usePost(urlConstants.LOGOUT, reqData);
}


export function useGetProfile() {
  return useGet(urlConstants.GET_PROFILE)
}

export function useUpdateProfile(reqData) {
  return usePost(urlConstants.UPDATE_PROFILE, reqData)
}

export function useChangePassword(reqData) {
  return usePost(urlConstants.CHANGE_PASSWORD, reqData)
}

export function useForgotPassword(reqData) {
  return usePost(urlConstants.FORGOT_PASSWORD, reqData)
}

export function useBrands(id, reqData) {

  return usePost(urlConstants.BRAND + id, reqData)
}


export function useCheckUser(reqData) {
  return usePost(urlConstants.CHECK_USER, reqData)
}

export function useSendOtp(reqData) {
  return usePost(urlConstants.SEND_OTP, reqData)
}

export function useVerifyOtp(reqData) {
  return usePost(urlConstants.VERIFY_OTP, reqData)
}

export function useRegister(reqData) {
  return usePost(urlConstants.REGISTER, reqData)
}

export function useWishListApi(reqData) {
  return usePost(urlConstants.WISHLIST, reqData)
}

export function useUpsertWishlist(reqData) {
  return usePost(urlConstants.WISHLIST + '/upsert', reqData);
}

export function useDeleteWishlist(id) {
  return useDelete(urlConstants.WISHLIST + '/delete/')
}

export function useProductData(reqData) {
  return usePost(urlConstants.PRODUCTS, reqData);
}

export function useGetProductById(id) {
  return useGetById(`${urlConstants.PRODUCTS}/${id}`);
}

export function useAddToCart(reqData) {
  return usePost(urlConstants.ADD_TO_CART, reqData)
}

export function useGetCartItem(reqData) {
  return usePost(urlConstants.GET_CART_PRODUCT)
}

export function useGetCheckOut() {
  return useGet(urlConstants.GET_CHECKOUT_DETAILS)
}

export function usePlaceOrder(reqData) {
  return usePost(urlConstants.PLACED_ORDER, reqData);
}

export function useRemoveCart(reqData) {
  return usePost(urlConstants.EMPTY_CART, reqData);
}

export function useEmptyCart(reqData) {
  return usePost(urlConstants.EMPTY_CART, reqData);
}
export function useGetOrderList(reqData) {
  return usePost(urlConstants.ORDER, reqData)
}

export function useGetAddressList(reqData) {
  return useGet(urlConstants.ADDRESS, reqData)
}

export function useAddAddress(reqData) {
  return usePost(`${urlConstants.ADD_ADDRESS}`, reqData)
}
export function useUpdateAddress(reqData, id) {
  return usePost(`${urlConstants.UPDATE_ADDRESS}/${id}`, reqData)
}

export function useDeleteAddress(id) {
  return useDelete(urlConstants.ADDRESS + '/' + id)
}

export function useUpdatePassword(reqData) {
  return usePost(urlConstants.UPDATE_PASSWORD, reqData)
}

export function useApplyCoupon(reqData, id) {
  return usePost(`${urlConstants.COUPON}/${id}`, reqData)
}

export function useGetCoupon() {
  return useGet(urlConstants.GET_COUPON)
}
export function useGetOrderById(id) {
  return useGetById(`${urlConstants.ORDER}/${id}`);
}

export function useOrderCancel(id, reqData) {
  return usePost(`${urlConstants.ORDER_CANCEL}/${id}`, reqData)
}
