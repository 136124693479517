import React from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../Components/_widgets/Button/Button';
import './Payments.scss';
import IconResource from '../../Wrapper/IconResource/IconResource';
import { formattedValue } from '../../Services/common';
import Dropdown from '../../Components/_widgets/Dropdown/Dropdown';
import qrpng from "../../Assets/img/qr.png";
import paytm from '../../Assets/img/paytm.png'
import * as toast from "../../Wrapper/toast/toast";
const PaymentsUi = ({ applyCoupon, buttonLoading, placedOrder, data, getCoupon, allCoupon, appliedCouponData, applyCouponSelection, setFillCoupon, setSelectCoupon, fillCoupon, selectCoupon, clearCoupon, setClearCoupon, setAppliedCouponData, couponLoading }) => {



  return (
    <Container className="my-5 mx-3">
      <Row>
        {/* Left Column - Offers and Payment Methods */}
        <Col sm={12} md={6} lg={8}>

          <Row className='gx-4 gy-sm-3 gy-3'>

            <Col md={12} lg={12} xl={5}>
              <div className='qroption p-3'>

                <Form>
                  <div className='d-flex w-100 align-items-center justify-content-start gap-2'>
                    <Form.Check
                      type="radio"
                      id="radio1"
                      name="paymentOption"
                      value=""
                      label=""
                      className='p-0 gap-1 d-flex  justify-content-start align-items-center'

                    />
                    <Form.Check
                      type="radio"
                      id="radio2"
                      name="paymentOption"
                      className='p-0 my-auto gap-1 d-flex  justify-content-start align-items-center'
                      value="Bank Transfer"
                      label="UPI-ID"
                      defaultChecked={true}
                    // onChange={handleRadioChange}
                    // checked={selectedOption === "Bank Transfer"}
                    />
                  </div>

                </Form>
                <div className='d-flex w-100 justify-content-between'>
                  <div>
                    <img
                      src={paytm}
                      style={{ display: "flex" }}
                      alt="QR Code"

                    />
                  </div>
                  <div className="qr-code">
                    <img
                      src={qrpng}

                      alt="QR Code"

                    />
                  </div>
                </div>


              </div>
            </Col>


            <Col md={12} lg={12} xl={7}>
              <div className='payment-option'>
                <div className="p-3">
                  <Form>
                    <div className='d-flex w-100 align-items-center justify-content-start gap-2'>
                      <Form.Check
                        type="radio"
                        id="radio1"
                        name="paymentOption"
                        value=""
                        label=""
                        className='p-0 gap-1 d-flex  justify-content-start align-items-center'

                      // onChange={handleRadioChange}
                      // checked={selectedOption === "Bank Transfer"}
                      />
                      <Form.Check
                        type="radio"
                        id="radio2"
                        name="paymentOption"
                        className='p-0 gap-1 d-flex  justify-content-start align-items-center'
                        value="Bank Transfer"
                        label="Bank Transfer"
                        defaultChecked={true}
                      // onChange={handleRadioChange}
                      // checked={selectedOption === "Bank Transfer"}
                      />
                    </div>
                    <p align="left">How would you like to pay?</p>
                    <div className='bank-transfer'>
                      <div>
                        <IconResource size={16} type="bank" />
                        <p>Bank Account</p>
                      </div>
                      <div>
                        <IconResource size={16} type="debit" />
                        <p>Debit Cart</p>
                      </div>
                      <div>
                        <IconResource size={16} type="credit" />
                        <p>Credit Card</p>
                      </div>
                      <div>
                        <IconResource size={16} type="bank" />
                        <p>Bank Account</p>
                      </div>
                    </div>

                  </Form>

                </div>
              </div>

            </Col>
            <Col md={12} lg={12} xl={5}>
            </Col>
            <Col md={12} lg={12} xl={7}>

              <div className='my-2'>
                <Button
                  buttonType="fullwidth"
                  text={"Continue"}
                >
                </Button>
              </div></Col>
          </Row>


        </Col>

        {/* Right Column - Apply Coupon and Order Summary */}
        <Col sm={12} md={6} lg={4}>
          <Card className="mb-3">
            <Card.Body className='border border-primary'>

              <Form.Group controlId="couponCode text-start">
                <Form.Label className='fw-bolder h6'>Apply Coupon Code</Form.Label>
                <div className='d-flex align-items-center border border-primary'>
                  <Form.Control
                    onChange={(e) => {
                      setClearCoupon(true)
                      setAppliedCouponData("")
                      setFillCoupon({
                        label: e.target.value,
                        value: e.target.value
                      })
                      setSelectCoupon({
                        label: '',
                        value: ''
                      })
                    }}
                    value={fillCoupon.label}
                    className='pb-2 pt-2 ps-2' type="text" placeholder="Enter coupon code" />
                  <Button
                    isLoading={buttonLoading}
                    onClick={() => {

                      if (fillCoupon.value.length > 0) {

                        applyCoupon()

                      }
                      else {
                        toast.warn("Please enter coupon code")
                      }
                    }}
                    buttonType="primary" text="Apply"></Button>
                </div>
              </Form.Group>
              <div className='d-flex align-items-center mt-3 justify-content-between w-100'>
                <Button buttonType="text" text="Show Coupons"></Button>
                <Button

                  onClick={(e) => getCoupon()}
                  icon={<IconResource type="plus" />}
                  buttonType="icon" ></Button>


              </div>
              {
                allCoupon?.length > 0 && !clearCoupon ? (
                  <Dropdown
                    id="dropdown-basic-button"
                    isMulti={false}
                    placeholder={'Please select coupon'}
                    isClearable={clearCoupon}
                    isLoading={couponLoading}
                    isSearchable={false}
                    value={selectCoupon || null}  // Pass `selectCoupon` as the selected value, or null if none selected
                    onChange={(selectedOption) => {
                      setSelectCoupon(selectedOption);
                      applyCouponSelection(selectedOption) // Update the selected coupon
                      setFillCoupon({
                        label: '',
                        value: ''
                      }) // Additional logic for applying coupon if necessary
                    }}
                    options={allCoupon}
                  />
                ) : null
              }


            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body className='border border-1'>
              <div className='d-flex pt-2 pb-3'>
                <img></img>
                <div className='estimated-delivery text-start'>
                  <h6 className='fw-bold mb-2'>Estimated Delivery: 26 Oct, 2024</h6>
                  <h6>Get this product delivered to your doorstep by 3.00PM to 5.00PM</h6>
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Order Summary Section */}
          <Card className="mb-3">
            <Card.Body>
              {
                data?.products?.length > 0 ?

                  <div className="payment-summary-box p-3 bg-white rounded-2 border">
                    <div className='d-flex justify-content-between pb-2'>
                      <h6 className='small'>Order Items</h6>
                      <h6 className='fw-bold small'>{data?.order_items} Items</h6>
                    </div>
                    <div className="d-flex justify-content-between pb-2">
                      <h6 className='small'>Total MRP</h6>
                      <h6 className='small fw-bold'> {formattedValue(data?.total_mrp, true)}</h6>
                    </div>
                    <div className="d-flex justify-content-between pb-2">
                      <h6 className='small'>Discount Savings</h6>
                      <h6 className='small fw-bold'>  - {formattedValue(data?.margin_savings, true)}</h6>
                    </div>
                    <div className="d-flex justify-content-between pb-2">
                      <h6 className='small'>Subtotal</h6>
                      <h6 className='small fw-bold'> {formattedValue(data?.subtotal, true)}</h6>
                    </div>
                    {
                      appliedCouponData?.discount && < div className="d-flex justify-content-between pb-2">
                        <h6 className='small'>Coupon Discount</h6>
                        <h6 className='small fw-bold'> {formattedValue(appliedCouponData?.discount, true)}</h6>
                      </div>
                    }

                    <div className="d-flex justify-content-between pb-2 mt-2">
                      <h5 className=''>Total</h5>
                      {
                        appliedCouponData?.discount > 0 ?
                          <div>
                            <h4 className='fw-bold' >{formattedValue(data?.total - appliedCouponData?.discount, true)}*</h4>
                            <p>*excl. of delivery fee</p>
                          </div> :
                          <div>
                            <h4 className='fw-bold' >{formattedValue(data?.total, true)}*</h4>
                            <p>*excl. of delivery fee</p>
                          </div>
                      }

                    </div>

                    <p className='saving text-success fw-bold py-1 mt-2'>You have saved {appliedCouponData.discount > 0 ? formattedValue((parseInt(data?.margin_savings) + parseInt(appliedCouponData.discount)), true) : formattedValue(data?.margin_savings, true)} on this order!</p>

                    <Button
                      onClick={(e) => {
                        window.scrollTo(0, 0)
                        placedOrder(data)
                      }}
                      buttonType="fullwidth" text="Place Order"></Button>

                  </div>
                  : null
              }


            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container >
  );
};

export default PaymentsUi;