import { useState } from "react";
import * as urlconstant from "../Constants/urlConstants";
import * as apiProvider from "./../Services/provider";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryDelete } from "../Services/ReactQueryProvider"; // Importing the hook

const useDelete = (url, isQueryEnabled = urlconstant.IS_QUERY_ENABLE) => {
    const queryClient = useQueryClient();

    const deleteMutation = useQueryDelete(url); // Initialize it here to avoid calling the hook inside mutationFn

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const deleteData = async (id, callbacks = {}) => {
        if (!isQueryEnabled) {
            setIsLoading(true);

            try {
                // Call the post function directly for non-query-based mutation
                const response = await apiProvider.deleteAPI(url + id);
                setData(response);
                if (callbacks.onSuccess) callbacks.onSuccess(response);
            } catch (err) {
                setError(err);
                if (callbacks.onError) callbacks.onError(err);
            } finally {
                setIsLoading(false);
            }
        } else if (isQueryEnabled) {
            // Use the mutate function from useQueryPost hook

            deleteMutation.mutate(id, {

                onSuccess: (data) => {

                    queryClient.invalidateQueries(); // Invalidates queries after success
                    if (callbacks.onSuccess) callbacks.onSuccess(data);
                },
                onError: (err) => {

                    if (callbacks.onError) callbacks.onError(err);
                },
            });
        }
    };
    return {
        mutate: deleteData, // Expose the mutate function
        isLoading: isQueryEnabled ? deleteMutation.isLoading : isLoading,
        error: isQueryEnabled ? deleteMutation.error : error,
        data: isQueryEnabled ? deleteMutation.data : data,
        isError: isQueryEnabled ? deleteMutation.isError : !!error,
    };
};

export default useDelete;
