import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';

export function masterData(query) {
  return apiProvider.getAll(urlConstants.MASTER_DATA + query);
}

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);
}

export function logout(reqData) {
  return apiProvider.post(urlConstants.LOGOUT, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.GET_PROFILE)
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PROFILE, reqData)
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function home(reqData) {
  return apiProvider.getAll(urlConstants.HOME, reqData)
}

export function Brands(id, reqData) {
  return apiProvider.post(urlConstants.BRAND + id, reqData)
}

export function checkUser(reqData) {
  return apiProvider.post(urlConstants.CHECK_USER, reqData)
}

export function sendOtp(reqData) {
  return apiProvider.post(urlConstants.SEND_OTP, reqData)
}

export function verifyOtp(reqData) {
  return apiProvider.post(urlConstants.VERIFY_OTP, reqData)
}

export function register(reqData) {
  return apiProvider.post(urlConstants.REGISTER, reqData)
}

export function getWishlistsList(reqData) {
  return apiProvider.post(urlConstants.WISHLIST, reqData)
}

export function upsertWishlist(reqData) {
  return apiProvider.post(urlConstants.WISHLIST + '/upsert', reqData)
}

export function deleteWishlist(id) {
  return apiProvider.deleteAPI(urlConstants.WISHLIST + '/delete/' + id)
}

export function productData(reqData) {
  return apiProvider.post(urlConstants.PRODUCTS, reqData);
}
export function getProductById(id) {
  return apiProvider.get(urlConstants.PRODUCTS, `/${id}`);
}
export function addToCart(reqData) {
  return apiProvider.post(urlConstants.ADD_TO_CART, reqData)
}
export function getCartItem(reqData) {
  return apiProvider.post(urlConstants.GET_CART_PRODUCT)
}
export function getCheckOut() {
  return apiProvider.getAll(urlConstants.GET_CHECKOUT_DETAILS)
}
export function placeOrder(reqData) {
  return apiProvider.post(urlConstants.PLACED_ORDER, reqData);
}
export function removeCart(reqData) {
  return apiProvider.post(urlConstants.EMPTY_CART, reqData);
}
export function emptyCart(reqData) {
  return apiProvider.post(urlConstants.EMPTY_CART, reqData);
}
export function getOrderList(reqData) {
  return apiProvider.post(urlConstants.ORDER, reqData)
}
export function getAddressList(reqData) {
  return apiProvider.getAll(urlConstants.ADDRESS, reqData)
}

export function addAddress(reqData) {
  return apiProvider.post(`${urlConstants.ADD_ADDRESS}`, reqData)
}
export function updateAddress(reqData, id) {
  return apiProvider.post(`${urlConstants.UPDATE_ADDRESS}/${id}`, reqData)
}
export function deleteAddress(id) {
  return apiProvider.deleteAPI(urlConstants.ADDRESS + '/' + id)
}
export function updatePassword(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PASSWORD, reqData)
}
export function applyCoupon(reqData, id) {
  return apiProvider.post(`${urlConstants.COUPON}/${id}`, reqData)
}
export function getCoupon() {
  return apiProvider.getAll(urlConstants.GET_COUPON)
}
export function getOrderById(id) {
  return apiProvider.get(urlConstants.ORDER, `/${id}`);
}
export function orderCancel(id, reqData) {
  return apiProvider.post(`${urlConstants.ORDER_CANCEL}/${id}`, reqData)
}