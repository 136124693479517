import { useState } from "react";
import * as urlconstant from "../Constants/urlConstants";
import * as apiProvider from "./../Services/provider";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryPost } from "../Services/ReactQueryProvider"; // Importing the hook
const usePost = (url, payload, isQueryEnabled = urlconstant.IS_QUERY_ENABLE) => {
    const queryClient = useQueryClient();
    const postMutation = useQueryPost(); // Now correctly uses mutation

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (payload, callbacks = {}) => {
        if (!isQueryEnabled) {
            setIsLoading(true);
            try {
                const response = await apiProvider.post(url, payload);
                setData(response);
                if (callbacks.onSuccess) callbacks.onSuccess(response);
            } catch (err) {
                setError(err);
                if (callbacks.onError) callbacks.onError(err);
            } finally {
                setIsLoading(false);
            }
        } else {
            postMutation.mutate(

                { url, payload }, // Correct argument structure
                {
                    onSuccess: (data) => {
                        queryClient.invalidateQueries();
                        if (callbacks.onSuccess) callbacks.onSuccess(data);
                    },
                    onError: (err) => {
                        if (callbacks.onError) callbacks.onError(err);
                    },
                }
            );
        }
    };

    return {
        mutate: fetchData,
        isLoading: isQueryEnabled ? postMutation.isSuccess : isLoading,
        error: isQueryEnabled ? postMutation.error : error,
        data: isQueryEnabled ? postMutation.data : data,
        isError: isQueryEnabled ? postMutation.isError : !!error,
    };
};



export default usePost;
